import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../components/footer/Footer";
import NavbarFull from "../components/navbarFull/Navbar";
import "./sponsorForm.css";

const SponsorForm = () => {
  const formRef = useRef();
  const [error, setError] = useState(false);
  const [Success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_iqfs2rm", "template_zcveja6", formRef.current, {
        publicKey: "T74Z5RqgVPbCax8P1",
      })
      .then(
        (result) => {
          setSuccess(true);
        },
        (error) => {
          setError(true);
        }
      );
  };

  return (
    <>
      <article>
        <NavbarFull />
      </article>
      <main>
        <div className="container_SponsorForm">
          <div className="title__SponsorForm">
            <h2>Application Form</h2>
            <hr className="line__SponsorForm" />
          </div>
          <div className="form_SponsorForm">
            <form ref={formRef} onSubmit={sendEmail}>
              <input type="hidden" name="form-name" value="contact" />
              <p>
                <label for="fname">Full Name: </label>
              </p>
              <input
                className="field__SponsorForm"
                type="text"
                id="fname"
                required
                placeholder="First Name"
                name="fname"
              />
              <br />
              <br />
              <input
                className="field__SponsorForm"
                type="text"
                id="lname"
                required
                placeholder="Last Name"
                name="lname"
              />
              <p>
                <label for="email">Enter your email: </label>
              </p>
              <input
                className="field__SponsorForm"
                type="email"
                id="email"
                required
                placeholder="email@company.com"
                name="email"
              />
              <p>
                <label for="phone">Phone: </label>
              </p>
              <input
                className="field__SponsorForm"
                type="text"
                id="phone"
                required
                placeholder="123 456 7890"
                name="phone"
              />
              <p>
                <label for="organizaton">Name Of organization: </label>
              </p>
              <input
                className="field__SponsorForm"
                type="text"
                id="organizaton"
                required
                placeholder="Company Inc."
                name="organization"
              />
              <p>
                <label for="website">Website Domain Name: </label>
              </p>
              <input
                className="field__SponsorForm"
                type="text"
                id="website"
                required
                placeholder="www.myorganization.com"
                name="website"
              />
              <p>
                <label for="social">Social Media Accounts: </label>
              </p>
              <input
                className="field__SponsorForm"
                type="text"
                id="social"
                required
                placeholder="add you social handles..."
                name="socialMedia"
              />
              <p>
                <label for="profile">Company Profile</label>
              </p>
              <textarea
                className="field__SponsorForm"
                placeholder="write a brief profile of your company here..."
                name="profile"
                id="profile"
                rows={5}
              ></textarea>
              <br />
              <p>
                <label for="package">Type a Sponsorship Package choice:</label>
              </p>
              <input
                className="field__SponsorForm"
                type="text"
                id="package"
                required
                placeholder="Silver, Gold, Platinum"
                name="package"
              />
              <br />
              <br />
              <button className="button_register">Submit</button>
              <br />
              <br />
              {error && "Error"}
              {Success && "Success"}
              <br />
              <br />
            </form>
          </div>
        </div>
      </main>
      <article>
        <Footer />
      </article>
    </>
  );
};

export default SponsorForm;
